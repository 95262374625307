import React, { FC, useState } from "react";

import CustomerList from "../components/Customer/CustomerList";
/* import Filter from "../components/Filter/Filter"; */
import NavbarReports from "../components/Navigation/Navbar/Reports";

import { default as CustomerSettingsOverlay } from '../components/Customer/CustomerSettings/SettingsOvererlay/SettingsOverlay';
import Layout from '../components/Layout/Customer';
import NavbarMain from '../components/Navigation/Navbar/Main';
import SidebarCustomer from "../components/Navigation/Sidebar/Customer";
import SidebarMain from '../components/Navigation/Sidebar/Main';
import { default as UserSettingsOverlay } from '../components/User/UserSettings/SettingsOvererlay/SettingsOverlay';

import CustomerEventsList from '../components/Customer/Reports/Events/Events';
import { useNamedRoutes } from '../components/NamedRoutes';

import
{
  useCustomer,
  useMyCustomers
} from "../components/ReactData/customer";

import UserList from '../components/User/UserList';
import { MenuToggleProps } from "../components/Navigation/types";
import { useL10n } from "@ews/react-localization-context";
import { MenuLink } from "../components/Navigation/Sidebar/Sidebar";
import { addCircleSharp, filterOutline } from "ionicons/icons";
import NewCustomerModal from "../components/Customer/NewCustomer/NewCustomerModal";
import { ReactClickEvent } from "../components/types";
import { User, UserPermissions, permissionAllowed } from "@ews/websocket-service";
import { useMyPermissions } from "../components/Login/LoginProvider";
import { useRouteMatch } from "react-router";
import StatusbarMain from '../components/Navigation/Status/Main';
import { IonFab, IonFabButton, IonIcon } from "@ionic/react";
import { useIsMobile } from "../components/Responsive";

const CustomerSettings: React.FC = () =>
{
  const { params } = useNamedRoutes();
  const { id } = params();

  const customer = useCustomer(id!);
  //const customer = customerList.filter(c => c.id === id)[0];

  return <>
    <Layout customer={customer} backbutton={true} title={customer.name} navbar={NavbarReports} sidebar={SidebarCustomer} id="customer:settings">
      <CustomerSettingsOverlay customer={customer} />
    </Layout >
  </>;

};

const CustomerShowAllSystems: React.FC = () =>
{
  //const systems = useMySystems();
  const { params } = useNamedRoutes();
  const { id } = params();
  const customer = useCustomer(id!);


  return (
    <>
      <Layout customer={customer} backbutton={true} title={customer.name} navbar={NavbarReports} sidebar={SidebarCustomer} id="customer:systems">
        {/* <SystemList systems={customerSystemList} /> */}
      </Layout >
    </>

  );
};


const CustomerShowAllUsers: React.FC = () =>
{
  const { params } = useNamedRoutes();
  const { id } = params();

  const customer = useMyCustomers().filter(c => c.id === id)[0];
  const userList: any[] = [];//users.filter(user => user.customerId === id);

  return (
    <>
      <Layout customer={customer} backbutton={true} navbar={NavbarReports} sidebar={SidebarCustomer} id="customer:users">
        <UserList />
      </Layout >
    </>

  );
};

const CustomerAddCustomer: React.FC = () =>
{
  const { params } = useNamedRoutes();
  const { id } = params();
  const customer = useCustomer(id!);

  const newCustomer = {
    name: '',
    id: '',
    address: '',
    plz: 0,
    city: '',
    land: '',
    email: '',
    tel: ''
  };

  return <>
    <>
      <Layout customer={customer} backbutton={true} navbar={NavbarReports} sidebar={SidebarCustomer} id="customer:addcustomer">
        <CustomerSettingsOverlay customer={newCustomer} />
      </Layout>
    </>
  </>;
};

const CustomerAddUser: React.FC = () =>
{
  const { params } = useNamedRoutes();

  const { id } = params();
  const customer = useCustomer(id!);

  const newUser: User = {
    username: '',
    //customerId: id || "",
    password: '',
    active: false,
    email: '',
  };

  return <>
    <Layout customer={customer} backbutton={true} navbar={NavbarReports} sidebar={SidebarCustomer} id="customer:adduser">
      <UserSettingsOverlay user={newUser} />
    </Layout>
  </>;
};


const CustomerEvents: React.FC = () =>
{

  const { params } = useNamedRoutes();

  const { id } = params();
  const customer = useCustomer(id!);
  return (<>
    <Layout customer={customer} backbutton={true} navbar={NavbarReports} sidebar={SidebarCustomer} id="customer:events">
      <CustomerEventsList />
    </Layout >
  </>);
};

const SidebarWrapper: FC<MenuToggleProps> = ({ onMenuToggle }) =>
{
  const { translate: t } = useL10n();
  const permissions: UserPermissions = useMyPermissions();

  return (<SidebarMain onMenuToggle={onMenuToggle}>
    {permissionAllowed(permissions.createCustomer) ? <>
      <MenuLink
        title={t('Add customer')}
        id="newCustomer"
        icon={addCircleSharp}
      />
      <NewCustomerModal onDismiss={(e) => onMenuToggle && onMenuToggle(e as unknown as ReactClickEvent)} trigger={"newCustomer"} />
    </> : <></>}
  </SidebarMain>);
};


const Customer: React.FC<{ filterByCustomer?: boolean; }> = ({ filterByCustomer }) =>
{

  const { params } = useNamedRoutes();
  const { id: parentId } = params();

  const mobileView = useIsMobile();
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);

  const Bar = () =>
  {
    return <>
      {mobileView ? <IonFab horizontal='start'>

        <IonFabButton
          size="small"
          color={'tertiary'}
          onClick={() => setIsFilterOpen(!isFilterOpen)}>
          <IonIcon icon={filterOutline} />
        </IonFabButton>
      </IonFab> : null}
      <StatusbarMain></StatusbarMain>
    </>;
  };

  return (
    <>
      <Layout header={false} statusbar={Bar} navbar={NavbarMain} sidebar={SidebarWrapper} id="customer">
        <CustomerList
          parentId={filterByCustomer ? parentId : undefined}
          isFilterOpen={isFilterOpen}
          onIsOpen={(open) => setIsFilterOpen(open)}
        />
        {/* <CustomerAddBtn
          id={"NewCustomer"}
          icon={add}
          color={"success"}
          onClick={() =>
          {
            setOpenAddSystemSettings(!openAddSystemSettings);
          }} />
        <NewCustomerModal trigger="NewCustomer" /> */}
      </Layout>

    </>

  );
};
export
{
  Customer, CustomerAddCustomer,
  CustomerAddUser, CustomerEvents, CustomerSettings,
  CustomerShowAllSystems,
  CustomerShowAllUsers
};

