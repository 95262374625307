import
{
    FC
} from "react";

import
{
    useNamedRoutes
} from "../NamedRoutes";

import
{
    useSystem
} from "../ReactData/system";

import
{
    SystemStatusIcon
} from "../Icons";

import
{
    SystemStatus as SysStat
} from '@ews/websocket-service';

export const useCurrentSystemStatus = () =>
{
    const { params } = useNamedRoutes();
    const { id } = params();
    return useSystemStatus(id || "");
};

export const useSystemStatus = (id: string) =>
{
    const system = useSystem(id!);
    return system ? system?.status : SysStat.UNKNOWN;
};

export const SystemStatus: FC = () =>
{
    const status = useCurrentSystemStatus();
    return <SystemStatusIcon status={status} />;
};